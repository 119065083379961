var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"elevation-1",attrs:{"loading":_vm.formLoading,"disabled":_vm.formLoading}},[_c('v-card-text',[_c('p',{staticClass:"mb-4 text-5"},[_c('v-icon',[_vm._v(_vm._s(_vm.icon.info))]),_vm._v(" Select parameter then click generate.")],1),_c('v-form',[_c('v-radio-group',{staticClass:"pt-0 mt-0",attrs:{"row":""},model:{value:(_vm.viewAs),callback:function ($$v) {_vm.viewAs=$$v},expression:"viewAs"}},[_c('v-radio',{attrs:{"label":"Table View","value":"table-view"}}),_c('v-radio',{attrs:{"label":"PDF","value":"pdf"}}),_c('v-radio',{attrs:{"label":"Excel","value":"excel"}})],1),_c('v-autocomplete',{class:_vm.formErrorFields.groupByErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"items":['Employee', 'Department', 'Outlet', 'Department & Outlet'],"outlined":"","dense":"","clearable":"","label":"Group By","error-messages":_vm.formErrorFields.groupByErrorMessage},on:{"keydown":function($event){_vm.formError().remove('groupBy', _vm.formErrorFields)}},model:{value:(_vm.groupBy),callback:function ($$v) {_vm.groupBy=$$v},expression:"groupBy"}}),_c('v-autocomplete',{class:_vm.formErrorFields.rangeTypeErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"outlined":"","dense":"","clearable":"","label":"Range Type","item-value":"id","item-text":"name","items":['Month', 'Cutoff', 'Custom'],"error-messages":_vm.formErrorFields.rangeTypeErrorMessage},on:{"change":function($event){_vm.formError().remove('rangeType', _vm.formErrorFields)}},model:{value:(_vm.rangeType),callback:function ($$v) {_vm.rangeType=$$v},expression:"rangeType"}}),(_vm.rangeType!=='Custom')?[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.payrollPeriodValue,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.payrollPeriodValue=$event},"update:return-value":function($event){_vm.payrollPeriodValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.formErrorFields.payrollPeriodErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"loading":_vm.payrollPeriodLoading,"label":_vm.rangeType == 'Cutoff' ? 'Payroll Period' : 'Date',"outlined":"","dense":"","placeholder":_vm.rangeType == 'Cutoff' ? 'Payroll Period' : 'Date',"autocomplete":"off","append-icon":_vm.icon.calendar,"error-messages":_vm.formErrorFields.payrollPeriodErrorMessage},on:{"click":function($event){_vm.formError().remove('payrollPeriod', _vm.formErrorFields)}},model:{value:(_vm.payrollPeriod),callback:function ($$v) {_vm.payrollPeriod=$$v},expression:"payrollPeriod"}},'v-text-field',attrs,false),on))]}}],null,false,2969753215),model:{value:(_vm.modalPayrollPeriod),callback:function ($$v) {_vm.modalPayrollPeriod=$$v},expression:"modalPayrollPeriod"}},[_c('v-date-picker',{attrs:{"scrollable":"","type":_vm.rangeType==='Month' ? 'month' : 'date'},model:{value:(_vm.payrollPeriod),callback:function ($$v) {_vm.payrollPeriod=$$v},expression:"payrollPeriod"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalPayrollPeriod=false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalPayrollPeriod=false}}},[_vm._v(" OK ")])],1)],1)]:[_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.fromDateValue,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.fromDateValue=$event},"update:return-value":function($event){_vm.fromDateValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"label":"From Date","outlined":"","dense":"","placeholder":"From Date","autocomplete":"off","append-icon":_vm.icon.calendar,"error-messages":_vm.formErrorFields.fromDateErrorMessage},on:{"click":function($event){_vm.formError().remove('fromDate', _vm.formErrorFields)}},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalFromDate),callback:function ($$v) {_vm.modalFromDate=$$v},expression:"modalFromDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.fromDate),callback:function ($$v) {_vm.fromDate=$$v},expression:"fromDate"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFromDate=false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalFromDate=false}}},[_vm._v(" OK ")])],1)],1),_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.toDateValue,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.toDateValue=$event},"update:return-value":function($event){_vm.toDateValue=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({class:_vm.formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : '',attrs:{"label":"To Date","outlined":"","dense":"","placeholder":"To Date","autocomplete":"off","append-icon":_vm.icon.calendar,"error-messages":_vm.formErrorFields.toDateErrorMessage},on:{"click":function($event){_vm.formError().remove('toDate', _vm.formErrorFields)}},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modalToDate),callback:function ($$v) {_vm.modalToDate=$$v},expression:"modalToDate"}},[_c('v-date-picker',{attrs:{"scrollable":""},model:{value:(_vm.toDate),callback:function ($$v) {_vm.toDate=$$v},expression:"toDate"}},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalToDate=false}}},[_vm._v(" Cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modalToDate=false}}},[_vm._v(" OK ")])],1)],1)],_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.generate}},[_vm._v(" Generate ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"outlined":""},on:{"click":_vm.reset}},[_vm._v(" Reset ")])],2)],1),(_vm.message.length > 0)?_c('snackbar',{attrs:{"show":_vm.message.length > 0,"text":_vm.message,"color":_vm.messageStatus},on:{"close":function($event){_vm.message=''}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }